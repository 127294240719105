<template>
  <div>
    <v-container fluid>
      <v-skeleton-loader type="table" :loading="skLoader" :transition-group="'fade-transition'">
        <v-row class="vc d-flex-middle">

          <h1 class="titulo" style="width:100%">Dashboard</h1>

        <div cols="6" class="vc-content">
          <v-skeleton-loader 
            type="card" 
            :loading="loadBalance" 
            :transition-group="'fade-transition'"
          >
            <v-card elevation="5">
              <div class="vc-saldo text-center d-flex pb-5 pt-5">
                <v-card-text class="pb-1 vc-saldo-title"> Saldo Geral  </v-card-text>
                  {{ saldoGeral | currency }}
                <!-- <v-card-text class="pb-1 vc-saldo-title">  </v-card-text> -->
              </div>
            </v-card>
          </v-skeleton-loader>

          <v-row class="mb-4">
            <v-col cols="6">
              <v-card 
                :color="variables.colorPrimaryGecom"
                elevation="5"
                class="box-saldo"
                dark
              >
                <v-card-text
                  class="box-saldo__content"
                >
                  <v-skeleton-loader
                    class="mx-auto d-flex justify-center"
                    max-width="300"
                    type="heading"
                    :loading="loadAccount"
                  >
                    <strong>{{ getTotalReceber | currency }}</strong>
                  </v-skeleton-loader>
                  <span>À receber</span>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="6">
              <v-card 
                :color="variables.colorPrimaryGecom"
                elevation="5"
                class="box-saldo"
                dark
              >
                <v-card-text
                  class="box-saldo__content"
                >
                  <v-skeleton-loader
                    class="mx-auto d-flex justify-center"
                    max-width="300"
                    type="heading"
                    :loading="loadAccount"
                  >
                    <strong>{{ getTotalPagar | currency }}</strong>
                  </v-skeleton-loader>
                  <span>À pagar</span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <!-- MINHAS CONTAS -->
          <v-skeleton-loader type="card" :loading="loadAccount" :transition-group="'fade-transition'">
            <v-card elevation="5" class="vc-card mb-4">
              <div class="vc-contas text-center d-flex pt-1 pl-4 pb-2 pb-5 pt-4" style="min-height: 100%">
                <div class="vc-contas-title d-flex"> Minhas contas </div>
                  <div class="vc-contas-box mr-3">
                    <template v-for="(v, k) in accounts">
                      <a href="#" :key="k" class="mb-3" @click.prevent="irLAncamentosConta(v.id)">
                        <span class="image">
                          <img :src="v.Institutions !== null && v.Institutions.icon !== null ? v.Institutions.icon : 'https://e7.pngegg.com/pngimages/456/851/png-clipart-wallet-computer-icons-wallet-brown-leather-thumbnail.png'" /> 
                        </span>
                        <span class="vc-contas-box-description">
                          <big> {{ v.name }} </big>
                          <small> Conta corrente </small>
                        </span>
                        <span class="vc-contas-box-value"> {{ v.balance | currency}} </span>
                      </a>
                    </template>
                  </div>  
              </div>
            </v-card>
          </v-skeleton-loader>

            <!-- CONTAS A PAGAR -->
            <v-card elevation="5" class="vc-card mb-4">
              <div class="vc-contas text-center d-flex pt-1 pl-4 pb-2 pb-5 pt-4" style="min-height: 100%">
                <div class="vc-contas-title d-flex" @click="irLancamentos('D')"> Contas a pagar </div>
                <h5 style="width: 100%" class="vc-contas-title2 text-left mt-3 mb-3 pt-2 pb-2"> Contas a pagar atrasadas </h5>
                <v-skeleton-loader type="card" style="width: 100%" :loading="loadDespesas" :transition-group="'fade-transition'">
                  <div class="vc-contas-box">
                    <template v-for="(v, k) in recorPaymentD.atrasadas">
                      <a :key="k" class="mb-1" @click.prevent="irLancamentoPorId(v.id)">
                        <span class="image d-flex">
                          <v-icon small :style="{'font-size': '10px', color: 'red'}"> circle </v-icon>
                        </span>
                        <span class="vc-contas-box-description justify-content-left">
                          <big class="mt-1"> {{ v.description }} </big>
                          <small class="mb-2">  {{v.dtEmission | datas('DD/MM/YYYY') }} </small>
                        </span>
                        <span class="vc-contas-box-value r"> {{ v.value | currency}} </span>
                         <span class="d-flex justify-content-center vc-contas-box-status">
                            <v-icon class="vc-contas-box-status-icon" color="#ff7373" @click.stop="updateLancamento(v)">mdi-thumb-down-outline</v-icon>
                        </span>
                      </a>
                    </template>
                  </div>
                </v-skeleton-loader>

                <!-- ---- CONTAS A PAGAR PROXIMAS ------ -->
                <h5 v-if="recorPaymentD.apagar[0] !== undefined" style="width: 100%" class="vc-contas-title2 text-left mt-3 mb-3 pt-2 pb-2"> Próximas </h5>
                <v-skeleton-loader type="card" style="width: 100%" :loading="loadDespesas" :transition-group="'fade-transition'">
                  <div class="vc-contas-box">
                    <template v-for="(v, k) in recorPaymentD.apagar">
                      <a :key="k" class="mb-1" @click.prevent="irLancamentoPorId(v.id)">
                        <span class="image d-flex">
                          <v-icon small :style="{'font-size': '10px', color: 'green'}"> circle </v-icon>
                        </span>
                        <span class="vc-contas-box-description justify-content-left">
                          <big class="mt-1"> {{ v.description }} </big>
                          <small class="mb-2">  {{v.dtEmission | datas('DD/MM/YYYY') }} </small>
                        </span>
                        <span class="vc-contas-box-value r"> {{ v.value | currency}} </span>
                        <span class="d-flex justify-content-center vc-contas-box-status">
                            <v-icon class="vc-contas-box-status-icon" color="#ff7373" @click.stop="updateLancamento(v)">mdi-thumb-down-outline</v-icon>
                        </span>
                      </a>
                    </template>
                  </div>
                </v-skeleton-loader>
                <!-- Grafico de categorias -->
                <div style="width: 50%">
                </div>
              </div>
            </v-card>
        </div>




        <div cols="6" class="vc-content">
          <acessoRapido class="mb-4 ml-4" />

          <vendas class="mb-4 ml-4" />

          <!-- TOP TAGS -->
          <v-card elevation="5" class="vc-card mb-4 ml-4" v-if="false">
            <div class="vc-contas text-center d-flex pt-1 pl-4 pb-2 pb-5 pt-4" style="min-height: 100%">
              <div class="vc-contas-title d-flex"> Top tags dos últimos 6 meses </div>

              <v-skeleton-loader :class="[loadTags ? 'ml-3': '']" style="width: 50%" type="card" :loading="loadTags" :transition-group="'fade-transition'">
                <div class="vc-contas-box">
                  <template v-for="(v, k) in listTagsRecords">
                    <a href="javascript:void(0)" :key="k" class="mb-1">
                      <span class="image">
                        <v-icon small :style="{'font-size': '10px', color: colors[k]}"> circle </v-icon>
                      </span>
                      <span class="vc-contas-box-description justify-content-left" style="width:94%">
                        <big class="mt-1"> {{ v.name }} </big>
                      </span>
                    </a>
                  </template>
                </div> 
              </v-skeleton-loader>
              <!-- Grafico de despesas -->
              <div style="width: 50%">
                <grafico :colors="colors" :key="10" />
              </div>
            </div>
          </v-card>



            <!-- CATEGORIAS -->
            <v-card elevation="5" class="vc-card mb-4 ml-4" v-if="false">
              <div class="vc-contas text-center d-flex pt-1 pl-4 pb-2 pb-5 pt-4" style="min-height: 100%">
                <div class="vc-contas-title d-flex"> Top categorias dos últimos 6 meses </div>
                <!-- <h5 style="width: 100%" class="vc-contas-title2 text-left mt-3 mb-3 pt-2 pb-2"> Contas a pagar atrasadas </h5> -->
                <v-skeleton-loader type="card" style="width: 50%" :loading="loadCategoria" :transition-group="'fade-transition'">
                  <div class="vc-contas-box">
                    <template v-for="(v, k) in getCategoriasDashboard">
                      <a href="javascript:void(0)" :key="k" class="mb-1">
                        <span class="image">
                          <v-icon small :style="{'font-size': '10px', color: colors[k]}"> circle </v-icon>
                        </span>
                        <span class="vc-contas-box-description justify-content-left" style="width:94%">
                          <big class="mt-1"> {{ v.name }} </big>
                        </span>
                      </a>
                    </template>
                  </div>
                </v-skeleton-loader>
                <!-- Grafico de categorias -->
                <div style="width: 50%">
                  <graficoCategorias :colors="colors" :key="1123" />
                </div>
              </div>
            </v-card>

            <!-- RECEITAS -->
            <v-card elevation="5" class="vc-card mb-4 ml-4">
              <div class="vc-contas text-center d-flex pt-1 pl-4 pb-2 pb-5 pt-4" style="min-height: 100%">
                <div class="vc-contas-title d-flex" @click="irLancamentos('R')"> Contas a receber </div>
                <h5 style="width: 100%" class="vc-contas-title2 text-left mt-3 mb-3 pt-2 pb-2"> Contas a receber atrasadas  </h5>
                <v-skeleton-loader type="card" style="width: 100%" :loading="loadReceitas" :transition-group="'fade-transition'">
                  <div class="vc-contas-box">
                    <template v-for="(v, k) in recordListPendentes">
                      <a :key="k" class="mb-1" @click.prevent="irLancamentoPorId(v.id)">
                        <span class="image d-flex">
                          <v-icon small :style="{'font-size': '10px', color: 'green'}"> circle </v-icon>
                        </span>
                        <span class="vc-contas-box-description justify-content-left">
                          <big class="mt-1"> {{ v.description }} </big>
                          <small class="mb-2">  {{v.dtEmission | datas('DD/MM/YYYY') }} </small>
                        </span>
                        <span class="vc-contas-box-value r"> {{ v.value | currency}} </span>
                        <span class="d-flex justify-content-center vc-contas-box-status">
                            <v-icon class="vc-contas-box-status-icon" color="9797ad" v-if="v.status==='P'" @click.stop="updateLancamento(v, 'recebimento')">mdi-thumb-down-outline</v-icon>
                        </span>
                      </a>
                    </template>
                  </div>
                </v-skeleton-loader>
                <!-- Grafico de categorias -->
                <div style="width: 50%">
                </div>
              </div>
            </v-card>
        </div>
        </v-row>
      </v-skeleton-loader>
    </v-container>

    <ModalEfetivarPagamento />
    <modalLancamento :key="'dashboard-lancamentos'" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import variables from '@/assets/styles/helpers/_variables.scss'
import moment from 'moment'
import Events from '@/core/service/events'
moment.locale('pt-br')

export default {
  name: 'OrganizeDashboard',
  components: {
    modalLancamento: () => import('../financeiro/lancamentos/components/modalCadastro'),
    grafico: () => import('./components/grafico'),
    graficoCategorias: () => import('./components/graficoCategorias'),
    vendas: () => import('./components/vendas'),
    acessoRapido: () => import('./components/acessoRapido'),
    ModalEfetivarPagamento: () => import('@/views/organizze/financeiro/lancamentos/components/modalEfetivarPagamento'),
  },
  computed: {
    ...mapGetters('relatorioCategorias', ['getCategoriasDashboard']),
    ...mapGetters('organizzeFinanceiroLancamentos', ['balanceList', 'listTagsRecords', 'recordList', 'recorPaymentD', 'recordListPendentes']),
    ...mapGetters('organizzeFinanceiroContas', ['accounts']),
    colors () {
      return ['#419ef7', '#f35b5b', '#47ca82', '#fdc642', '#fac7d9', '#57d3ff', '#7D02EB', '#2fd1b3', '#54cbf5', '#A5978B', '#F86624', '#C5D86D', '#A300D6', '#5A2A27']
    },
    variables: () => variables,

    saldoGeral () {
      return this.accounts.filter(account => [105, 121].includes(account.id)).reduce((acc, cur) => acc + parseFloat(cur.balance), 0)
    },

    getTotalReceber () {
      return this.accounts.filter(account => [96].includes(account.id)).reduce((acc, cur) => acc + parseFloat(cur.balance), 0)
    },

    getTotalPagar () {
      return this.accounts.filter(account => [97,98].includes(account.id)).reduce((acc, cur) => acc + parseFloat(cur.balance), 0)
    }
  },

  data: () => ({
    skLoader: false,
    filtro: {},
    dateFormat: 'YYYY-MM-DD HH:mm:ss',
    loadBalance: true,
    loadAccount: true,
    loadTags: true,
    loadDespesas: true,
    loadCategoria: true,
    loadReceitas: true,
    lancamentoDespesas: {},
    lancamentoReceitas: {},
    typeLancamentos: null
  }),

  mounted () {
    const vm = this
    this.filtro['Records:dtEmission_>='] = moment().startOf('day').format(this.dateFormat)
    this.filtro['Records:dtEmission_<='] = moment().endOf('day').format(this.dateFormat)
    this.getBalance({'_addlFilter': this.filtro}).then(() => { vm.loadBalance = false })
    this.getAccounts().finally(() => { vm.loadAccount = false })
    this.getTagsRecors().finally(() => { vm.loadTags = false })
    this.getCategiriasDashboard().finally(() => { vm.loadCategoria = false})

    this.lancamentoDespesas = {
      'Records:type_=': 'D',
      'Records:status_=': 'P',
      'Records:dtDue_>=': moment().add(-3, 'month').startOf('month').format(this.dateFormat),
      'Records:dtDue_<=': moment().add(6, 'month').startOf('month').format(this.dateFormat)
    }
    this.getRecords({'_addlFilter': this.lancamentoDespesas}).finally(() => { vm.loadDespesas = false})
    
    this.lancamentoReceitas = {
      'Records:status_=': 'P',
      'Records:type_=': 'R',
      'Records:dtDue_>=': moment().add(-3, 'month').startOf('month').format(this.dateFormat),
      'Records:dtDue_<=': moment().format(this.dateFormat)
    }
    this.getRecordsPendentes({ '_addlFilter': this.lancamentoReceitas }).finally(() => { vm.loadReceitas = false})

    Events.$on('OrganizzeFilter::buscar', () => {
      if (vm.typeLancamentos === 'recebimento') {
        vm.loadReceitas = true
        vm.getRecordsPendentes({ '_addlFilter': vm.lancamentoReceitas })
            .finally(() => vm.loadReceitas = false)
      } else {
        vm.loadDespesas = true
        vm.getRecords({ '_addlFilter': vm.lancamentoDespesas })
          .finally(() => vm.loadDespesas = false)
      }
    })

  },
  methods: {
    ...mapActions('relatorioCategorias', ['getCategiriasDashboard']),
    ...mapActions('organizzeFinanceiroLancamentos', ['getBalance', 'getTags', 'getTagsRecors', 'getRecords', 'getRecordsPendentes', 'submit', 'getRecordId']),
    ...mapActions('organizzeFinanceiroContas', ['getAccounts']),
    updateLancamento(item, type = false) {
      if (type === 'recebimento') {
        this.typeLancamentos = type
      } else {
        this.typeLancamentos = 'pagamento'
      }
       const vm = this
      vm.loadDespesas = true
      vm.loadReceitas = true
      // vm.$router.push({ name: 'lancamentos', params: { id } })
      vm.getRecordId({ _addlFilter: { 'Records:id_=': item.id } }).then(record => {
        Events.$emit('modalCadastro::edit', { record: record[0] })
      }).finally(() => {
        vm.loadDespesas = false
        vm.loadReceitas = false
      })
      // Events.$emit('modalEfetivarPagamento::open', { record: item }) 
    },

    irLancamentos (id) {
      // this.$router.push({ name: 'lancamentos', params: { typeLancamentos: type } })
      const vm = this
      vm.loadDespesas = true
      vm.loadReceitas = true
      // vm.$router.push({ name: 'lancamentos', params: { id } })
      vm.getRecordId({ _addlFilter: { 'Records:id_=': id.id } }).then(record => {
        Events.$emit('modalCadastro::edit', { record: record[0] })
      }).finally(() => {
        vm.loadDespesas = false
        vm.loadReceitas = false
      })
    },

    irLancamentoPorId (id) {
      this.$router.push({ name: 'lancamentos', params: { id } })
    },

    irLAncamentosConta (id) {
      const initFilterParams = {
        organizzeFilter: [{ filterKey: 'Records:accountId_=', item: { accountId: id } }],
        otherFilter: { 'Records:accountId_=': id }
      }
      this.$router.push({ name: 'lancamentos', params: { initFilterParams }})
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/helpers/_variables.scss';
a {
  list-style: none;
  text-decoration: none;
}

.vc {
  max-width: 1200px;
  @media(max-width: 992px) { max-width: 920px; }
  display: flex;
  margin: 0 auto;
  &-content{
    width: 50%;
    @media(max-width: 992px) {
      width: 100%;
    }
  }
  &-card{
    // width: 100%;
  }
  &-saldo {
    width: 100%;
    background: #57d3ff;
    color: white;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 32px;
    &-title {
      font-size: 18px;
    }
  }

  &-contas {
    flex-wrap: wrap;
    color: #31304c;
    min-width: 450px;
    font-size: 18px;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    align-items: center;
    &-title {
      width: 100%;
      cursor: pointer;
    }
    &-title2 {
      background: #f7f8f8;
      color: #9797ad;
      font-weight: normal;
      font-size: 14px;
      padding-left: 20px;
    }
    &-box {
      a{
        border-bottom: 1px solid #eef1f5;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
      }
      width: 100%;
      .image {
        float: left;
        img {
          max-width: 40px;
        }
      }
      &-description{
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 65%;
        @media(max-width: 992px) {
          width: 70%;
        }
        @media(max-width: 760px) {
          width: 60%;
        }
        @media(max-width: 600px) {
          width: 50%;
        }
        big {
          display: flex;
          color: #5076ff;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
          white-space: nowrap;
          margin-left: 10px;
          width: 100%;
          align-items: flex-end;
        }
        small {
          width: 100%;
          display: block;
          margin-left: 10px;
          color: #9797ad;
          font-size: 12px;
          line-height: 12px;
          white-space: nowrap;
          display: inline-block;
        }
      }
      &-value {
        color: #5076ff;
        font-size: 16px;
        float: right;
        width: 25%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        &.r{
          color: #9797ad;
          font-size: 16px
        }
      }
      &-status {
        font-size: 12px;
        padding-left: 10px;
        display: flex;
        align-items: center;
        &-icon{
          font-size: 18px
        }
      }
    }
  }
}

.box-saldo {
    color: #FFF;
    &-titulo {
      text-align: center;
      font-size: .9rem;
      width: 100%;
      display: flex;
      padding-top: .4rem;
      justify-content: center;

      &-icon {
        margin-left: auto;
        cursor: pointer;
      }
    }

    &__content {
      text-align: center;
     span {
       color: #Fff;
       display: block;
     }

     strong {
       color: #Fff;
       font-size: 1.6rem;
     }
    }
  }

</style>